import React from 'react'
import { Link } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { Helmet } from 'react-helmet'
import * as indexStyles from '../css/index.module.css'
import '../css/MyFontsWebfontsKit.css'

const Index = () => (
  <>
    <Helmet bodyAttributes={{
        class: indexStyles.body
    }}>
      <meta charSet="iso-8859-1" />
      <title>AZNAP</title>
    </Helmet>
    <div className={`${indexStyles.container} ${indexStyles.TkachenkoSketch4FBoldNormal}`}>
      <p>&nbsp;</p>
      <p><Link to="/letoltes" className={indexStyles.link}>LETÖLTÉS // DOWNLOAD</Link></p>
      <p>&nbsp;</p>
      <p>Hamarosan jövünk!</p>
      <p>Addig is <a className={indexStyles.link} href="https://soundcloud.com/aznap" target="_blank" rel="noopener noreferrer">megtalálsz minket a
        SoundCloud-on</a>!
      </p>
      <p>&nbsp;</p>
      <p className={indexStyles.TkachenkoSketch4FBoldNormal}>
          <span className="title">AZNAP</span><br />
          Shaman Trip
      </p>
      <p>&nbsp;</p>
      <StaticImage src="../images/aznap_shaman_trip.jpg" alt="AZNAP Shaman Trip logo" />
    </div>
  </>
)

export default Index